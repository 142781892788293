window.dataLayer = window.dataLayer || [];
const order = document.querySelector(".order");
const single_product = document.querySelector(".product");

/**
 * trigger view item
 */
if (single_product) {
	const addToCartForm = single_product.querySelector("form.cart");
	const { name, id, price } = single_product.dataset;

	window.dataLayer.push({ ecommerce: null });
	window.dataLayer.push({
		event: "view_item", // "view_item", "add_to_cart", "begin_checkout", "purchase
		ecommerce: {
			currency: "EUR",
			value: parseFloat(price),
			items: [
				{
					item_id: id,
					item_name: name,
					affiliation: "DAPA Onlineshop",
					item_brand: "DAPA",
					price: parseFloat(price),
					quantity: 1,
				},
			],
		},
	});
	console.log({ "🚨 view item": window.dataLayer });

	/**
	 * trigger add item to cart
	 */
	if (addToCartForm) {
		addToCartForm.addEventListener("submit", (e) => {
			e.preventDefault();

			window.dataLayer.push({ ecommerce: null });
			window.dataLayer.push({
				event: "add_to_cart",
				ecommerce: {
					currency: "EUR",
					value: parseFloat(price),
					items: [
						{
							item_id: id,
							item_name: name,
							affiliation: "DAPA Onlineshop",
							item_brand: "DAPA",
							price: parseFloat(price),
							quantity: addToCartForm.querySelector('input[name="quantity"]').value,
						},
					],
				},
			});
			console.log({ "🚨 add to cart": window.dataLayer });
			e.target.submit();
		});
	}
}

/**
 * trigger view cart
 */
if (document.querySelector(".cart") && cart) {
	const cartItemsArray = [];
	cart.items.forEach((item) => {
		cartItemsArray.push({
			item_id: item.id,
			item_name: item.name,
			quantity: item.quantity,
			price: parseFloat(item.price),
		});
	});

	window.dataLayer.push({ ecommerce: null });
	window.dataLayer.push({
		event: "view_cart",
		ecommerce: {
			currency: "EUR",
			value: parseFloat(cart.total),
			items: cartItemsArray,
		},
	});
	console.log({ "🚨 begin checkout": window.dataLayer });
}

/**
 * trigger view checkout
 */
if (document.querySelector(".checkout") && checkout) {
	const checkoutItemsArray = [];

	checkout.items.forEach((item) => {
		checkoutItemsArray.push({
			item_id: item.id,
			item_name: item.name,
			quantity: item.quantity,
			price: parseFloat(item.price),
		});
	});

	window.dataLayer.push({ ecommerce: null });
	window.dataLayer.push({
		event: "checkout",
		ecommerce: {
			checkout: {
				actionField: { step: 1, option: "" },
				products: checkoutItemsArray,
			},
		},
	});
	console.log({ "🚨 checkout": window.dataLayer });
}

/**
 * trigger  purchase
 */
if (order) {
	const orderItems = order.querySelectorAll(".order__item");
	const orderID = order.dataset.id;
	const orderValue = order.querySelector(".order__cart .total").innerHTML.replace("€", "").replace(",", ".");
	const orderedItems = [];

	orderItems.forEach((item) => {
		orderedItems.push({
			item_id: item.dataset.id,
			item_name: item.querySelector(".name").innerHTML,
			quantity: item.querySelector(".qty").innerHTML,
			price: parseFloat(item.querySelector(".price").innerHTML.replace("€", "").replace(",", ".")),
		});
	});
	window.dataLayer.push({ ecommerce: null });
	window.dataLayer.push({
		event: "purchase",
		ecommerce: {
			transaction_id: orderID,
			value: parseFloat(orderValue),
			currency: "EUR",
			items: orderedItems,
		},
	});
	console.log({ "🚨 order complete": window.dataLayer });
}
